// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../css/Header.css'; // Import a custom CSS file for styling
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/custom.css'; // Import your custom CSS

const Header = () => {
  const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        //setIsExpanded(prevState => !prevState);
        setIsExpanded(!isExpanded);
        
    };

    useEffect(() => {
      const navbar = document.getElementById('main-nav');
      if (navbar) {
          if (isExpanded) {
              navbar.classList.remove('on-bottom');
          } else {
              navbar.classList.add('on-bottom');
          }
      }
  }, [isExpanded]);

  return (
    <>
      {/* Top Blue Strip */}
      <div className="top-div">
        <Container>
          <div className="top-bar">
          
            <div className="left">
            <i  class="fas fa-map-pin" style={{display:'flex', position:'absolute'}} ></i>
            <div className='top-address'>
             <a className="link" href="https://maps.app.goo.gl/Pa4CWoEavr9PRUj56" target="_blank">99-1 Route 25A, Shoreham, NY 11786</a>
              </div>
              </div>
              
            <div className="right">
            <div className='phone'>Call Now!&nbsp;</div>

              <div><a className='link' href="tel:+16318210707">(631) 821-0707&nbsp;&nbsp;&nbsp;</a></div>
              </div>
              
          </div>
        </Container>
      </div>

      {/* Navigation Bar */}
      <div className="navbar-container">
        <div className="logo">
            <a href='/'>
                <img className='logo' src="logo_main.png" alt="Logo" />
            </a>
        </div>
        <Navbar id="main-nav" className="nav-menu-position" bg="light" expand="lg">
            <Container>
            <Navbar.Toggle 
                    aria-controls="basic-navbar-nav" 
                    onClick={handleToggle} 
                />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="navbar-menu-items">
                        <Nav.Link as={NavLink} to="/" end>Home</Nav.Link>
                        <NavDropdown title="Services" id="services-dropdown">
                            <NavDropdown.Item as={NavLink} to="/services/free-home-delivery">Free Home Delivery</NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to="/services/cbd-products">CBD Products</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={NavLink} to="/refills">Refills</Nav.Link>
                        <Nav.Link as={NavLink} to="/transfer">Transfer</Nav.Link>
                        <Nav.Link as={NavLink} to="/about">About Us</Nav.Link>
                        <Nav.Link as={NavLink} to="/contact">Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
