import React, { useEffect, useRef, useState } from 'react';
import { Container, Carousel, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web'; // Import react-spring
import '../css/Home.css';
import '../css/Insurance.css';

const Home = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState('img_shoreham_pharmacy.png'); // Default image
  const sectionRef = useRef(null);
  
  const [isInView, setIsInView] = useState(false);
  const [isInViewWhyChoose, setIsInViewWhyChoose] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once visible
        }
      },
      {
        threshold: 0.4 // Trigger when 40% of the section is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    
    const updateImageBasedOnScreenState = (e) => {
      if (e.matches) {
        setImageSrc('img_shoreham_short.png'); // Replace with your dimmed image path
      } else {
        setImageSrc('img_shoreham_pharmacy.png');
      }
    };

    // Set initial image based on the current state
    updateImageBasedOnScreenState(mediaQuery);

    // Add event listener for changes
    mediaQuery.addEventListener('change', updateImageBasedOnScreenState);
    
    // Cleanup on component unmount
    return () => {
      mediaQuery.removeEventListener('change', updateImageBasedOnScreenState);
    };
  }, []);

  const handleButtonClick = () => {
    navigate('/Refills'); // Adjust the path according to your routing setup
  };

  const handleFindOutMore = () => {
    navigate('/Halloween'); // Adjust the path based on your routing setup
  };
  // Scroll handler for detecting when the logo section is in view
  const handleScroll = () => {
    const section = document.getElementById('insurance-section');
    const rect = section.getBoundingClientRect();
    const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;

    if (isVisible) {
      setIsInView(true);
    }
    else {
      setIsInView(false);
    }
  };

  // Scroll handler for detecting when the "Why Choose Us?" section is in view
  const handleScrollWhyChoose = () => {
    const section = document.getElementById('why-choose-us');
    const rect = section.getBoundingClientRect();
    const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
    if (isVisible) {
      setIsInViewWhyChoose(true);
    }
    else {
      setIsInViewWhyChoose(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScrollWhyChoose);
    return () => {
      // window.removeEventListener('scroll', handleScroll);
      // window.removeEventListener('scroll', handleScrollWhyChoose);
    };
  }, []);

  // Slide-in animation for the "Why Choose Us?" section
  const slideInWhyChoose = useSpring({
    transform: isInViewWhyChoose ? 'translateX(0)' : 'translateX(-100%)',
    opacity: isInViewWhyChoose ? 1 : 0,
    config: { tension: 120, friction: 30 },
  });

  // Animation for the heading when the section comes into view
  const slideIn = useSpring({
    transform: isInView ? 'translateX(0)' : 'translateX(-100%)',
    opacity: isInView ? 1 : 0,
    // config: { tension: 250, friction: 25 }
    config: { tension: 120, friction: 30 }
  });

  const logosAnimation = useSpring({
    opacity: isInView ? 1 : 0,
    transform: isInView ? 'translateY(0)' : 'translateY(20px)',
    //config: { tension: 250, friction: 25 }
    config: { tension: 120, friction: 30 }
  });

  return (
    <Container style={{marginTop:'0px'}} className="my-5">
      <Carousel interval={2000}>
        <Carousel.Item>
          <img className="d-block w-100" src="img_2.jpeg" alt="Second slide" />
          <Carousel.Caption className="carousel-caption">
            <h2>Convenient Prescription<br></br> Refills</h2>
            <p>Easily manage your prescriptions with our online refill service.</p>
            <Button variant="primary" type="button" style={{ display:'flex', alignItems:'flex-end', flexDirection:'column', fontSize: '1.25rem' }} onClick={handleButtonClick}>Refill Now</Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src="img_1.jpg" alt="First slide" />
          <Carousel.Caption className="carousel-caption">
            <h2>Same day FREE <br></br> Delivery</h2>
            <p className="feature-card-p">Our dedicated in-house delivery team ensures your medications reach you safely and quickly, right at your doorstep.</p>
            <Button variant="primary" type="button" style={{ display:'flex', flexDirection:'column', fontSize: '1.25rem' }}>Find out More</Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src="img_3.jpeg" alt="Third slide" />
          <Carousel.Caption className="carousel-caption">
            <h2>Wide Range of Health Products</h2>
            <p>Everything you need for your health, all in one place.</p>
            <Button variant="primary" type="button" style={{ display:'flex', alignItems:'flex-end', flexDirection:'column', fontSize: '1.25rem' }}>Find out More</Button>
          </Carousel.Caption>
        </Carousel.Item>
      <Carousel.Item>
          <img className="d-block w-100" src="img_4.jpeg" alt="Forth slide" />
          <Carousel.Caption className="carousel-caption">
            <h2>Flat 50% Off On Halloween Cards</h2>
            <p>Get ready for Halloween with a frightfully fantastic <bold>50% off</bold> all Halloween cards!</p>
            <Button variant="primary" type="button" onClick={handleFindOutMore} style={{ display:'flex', alignItems:'flex-end', flexDirection:'column', fontSize: '1.25rem' }}>Find out More</Button>
          </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

      <div id="why-choose-us" className='red-background'>
        <animated.h2 style={slideInWhyChoose}>Why Choose Us?</animated.h2>
        <div className="features-section" ref={sectionRef}>
          <div className={`feature-card ${isVisible ? 'fade-in' : ''}`}>
            <img src="img_1.jpg" alt="Free Home Delivery" />
            <h3>Free Home Delivery</h3>
            <p style={{textAlign:'justify'}}>At Shoreham Drugs, we understand that your time is valuable. That’s why we offer free home delivery for all your medication needs. Whether you’re managing a chronic condition or simply need over-the-counter products, our reliable delivery service ensures that your prescriptions arrive safely and promptly at your home.</p>
          </div>
          <div className={`feature-card ${isVisible ? 'fade-in' : ''}`}>
            <img src="img_blazing_speed.jpeg" alt="Health Resources" />
            <h3>Blazing-Fast Rx Processing</h3>
            <p style={{textAlign:'justify'}}>Say goodbye to long waits for your medication! Our advanced software systems ensure that your prescriptions and refills are processed at lightning speed, so you can get the care you need without the delay. Experience the convenience of quick and efficient service at our pharmacy.</p>
          </div>
          <div className={`feature-card ${isVisible ? 'fade-in' : ''}`}>
            <img src="img_4.jpg" alt="Expert Pharmacist Consultation" />
            <h3>Expert Pharmacist Consultation</h3>
            <p style={{textAlign:'justify'}}>Talk to our licensed pharmacists for personalized medication advice. Our team is here to help you understand your medications, manage potential interactions, and answer any questions you may have about your health.</p>
          </div>
        </div>
      </div>

      <section id="insurance-section" className="insurance-section">
      <div className="container">
        <animated.h2 style={slideIn}>We cover all the major govt. and private insurances</animated.h2>
        <animated.div style={logosAnimation} className="logos">
          <img src="medicare-logo.svg" alt="Medicaid" className="insurance-logo" />
          <img src="medicaid_logo.svg" alt="Medicare" className="insurance-logo" />
          <img src="cvs_caremark_logo.svg" alt="CVS CareMark" className="insurance-logo" />
          <img src="Express_Scripts_logo.png" alt="Express Scripts" className="insurance-logo" />
          <img src="aptum-logo.png" alt="Aptum" className="insurance-logo" />
        </animated.div>
      </div>
    </section>

      <div className="store-front-pic">
        <img style={{width:'100%'}} src={imageSrc} alt="Store Front" />
      </div>
    </Container>
  );
};

export default Home;
