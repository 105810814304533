// src/pages/Transfer.js
import React from 'react';
import { Container } from 'react-bootstrap';

const Transfer = () => {
  return (
    <Container className="my-5">
      <h1>Transfer</h1>
      <p>Transfer your prescriptions to our pharmacy.</p>
    </Container>
  );
};

export default Transfer;
